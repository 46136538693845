@import "../variables";

#main {
    .ant-table-thead > tr > th {
        background: $white;
        font-size: 12px;
        line-height: 1.2;
        color: $blue_bright;
        font-weight: 500;

        .ant-table-column-sorters {
            font-weight: 500;
        }

        &.ant-table-column-sort {
            background: $white;
        }

        .ant-table-column-sorter {
            .ant-table-column-sorter-inner {
                height: 18px;
            }
        }

        @media #{$md} {
            font-size: 15px;
        }
    }

    .ant-table-tbody {
        td {
            font-size: 12px;
            color: $darker_blue;
            line-height: 1.2;

            @media #{$lg} {
                font-size: 14px;
            }
        }
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 8px 14px;
    }
}
