@import "../../../styles/variables";


.chatModal {
    position: fixed;
    top: 20px;
    transition: all 0.4s;
    z-index: 9999;
    right: 50%;
    transform: translate(50%, 0);
}

.videoChatWrap {
    width: 560px;
    max-width: 270px;
    box-shadow: -20px 0px 44px rgba(6, 28, 63, 0.2);
    border-radius: 10px;
    background-color: $white;
    display: flex;
    flex-direction: column;

    @media #{$md} {
        flex-direction: row;
        max-width: 560px;
    }
}

.media {
    position: relative;
    width: 270px;
    min-width: 270px;
    max-height: 300px;
    display: flex;

    .photo {
        background-position: center center;
        background-size: cover;
        position: relative;
        flex: 1;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(20px);
            pointer-events: none; /* make the pseudo class click-through */
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: block;
        }
    }

    @media #{$md} {
        min-height: 360px;
        max-height: initial;

        .photo {
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0;
        }
    }
}

.noPhoto {
    position: relative;
    width: 270px;
    height: 40vh;
    max-height: 360px;
    background-color: $gray_white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    filter: initial;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .userIcon {
        width: 200px;
        height: 200px;
        margin-bottom: 0;
    }

    @media #{$md} {
        max-height: 360px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
    }
}

.subscriber {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: block;
    background-color: $gray;
    overflow: hidden;


    @media #{$md} {
        min-height: 360px;
        max-height: initial;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
    }
}

.publisher {
    overflow: hidden;
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 140px;
    height: 110px;
    transition: 0.4s all;
    border-radius: 6px;
    background-color: $gray7;
    z-index: 2;
}

.hidden {
    display: none;
}

.info {
    width: 100%;
    position: relative;
    padding-bottom: 130px;
}

.box {
    padding: 20px 20px 0;

    p {
        font-size: 13px;
        line-height: 1.2;
        color: $gray62;
        margin-bottom: 20px;
    }
}

.title {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 1.2;
    color: $primary_blue;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.siteName {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 12px;
    color: $black;
}

.unlockedMsg {
    color: $green;
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: -5px;
}

.unlockBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    button {
        width: 100%;
        height: 50px;
        font-size: 20px;
        font-weight: 500;
        border-radius: 0 0 10px 10px;

        @media #{$md} {
            border-radius: 0 0 10px 0;
        }
    }
}

.actions {
    display: flex;
    justify-content: flex-start;

    > div {
        margin-left: 18px;

        &:first-child {
            margin-left: 0;
        }
    }

    .actionBtn {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        position: relative;
        display: inline-flex;
        padding: 0 !important;

        svg {
            width: 24px;
            height: 24px;
        }

        path {
            stroke: $white;
        }
    }
}

.redLine {
    position: absolute;
    width: 90%;
    height: 2px;
    background-color: $red;
    transform: rotate(-45deg);
}

.btnTxt {
    font-size: 10px;
    line-height: 1.2;
    color: $primary_blue;
    font-weight: 500;
    margin-top: 5px;
    display: block;
    text-align: center;

    &.red {
        color: $red;
    }
}

.autoEndCallBox {
    margin-top: 20px;

    p {
        padding: 0 10px;
        margin-bottom: 0;
    }

    span {
        width: 20px;
    }
}

.missBtn {
    &, &:hover {
        background-color: $red !important;
    }
}

.deviceSelection {
    width: 20px;
    height: 20px;
}

.deviceSelectBox {
    margin-bottom: 20px;
}

.answerBtns {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 70px;
    left: 20px;
    right: 20px;

    .answerBtn {
        margin-right: 10px;
        width: 50%;

        svg {
            fill: $green;
            width: 16px;
        }
    }

    .ignoreBtn {
        margin-left: 10px;
        width: 50%;
        svg {
            width: 16px;
            path {
                stroke: $red;
            }
        }
    }
}
