@import "../../../styles/variables";

.overlay {
    position: fixed !important;
}

.settingsBtn {
    padding-top: 10px;
    font-size: 14px;
    line-height: 1.2;
    color: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: capitalize;

    svg {
        margin-left: 5px;
        font-size: 10px;
    }
}

.settingsBtnTxt {
    width: 100%;
    padding-right: 5px;

    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.65);
    }
}

.menu {
    min-width: 240px;
    padding: 4px 0 !important;

    li {
        padding: 0 10px !important;
        list-style: none;
        position: relative;

        ul {
            margin: 0 !important;
            overflow: auto;
            max-height: 320px;

            /* autoprefixer ignore next */
            -ms-overflow-style: none;

            /* autoprefixer ignore next */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &, a, p, div {
            font-size: 14px;
            line-height: 1.2;
            color: $dark_blue !important;
            font-weight: 500;
        }

        a {
            display: block;
            padding: 10px;
            margin: 0;
        }

        &:hover {
            background: $light_gray !important;
            border-radius: 6px !important;
        }
    }

    .menuTitle {
        &:hover {
            background-color: transparent !important;
        }
        > div {
            padding: 20px 10px 10px !important;
        }
    }

    p {
        padding: 0;
        margin: 0;
    }

    .subNavItem {
        padding: 10px 20px 10px 25px !important;

        &:before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: $soft_dark_gray;
            position: absolute;
            border-radius: 50%;
            left: 10px;
            top: 18px;
            display: none;
        }

        &:hover:before {
            display: block;
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 14px;
            color: $soft_blue;
            font-weight: 400;
            margin-top: 3px;
        }
    }

    .subNavItemActive {
        &:before {
            background-color: $primary_blue;
            display: block;
        }
    }

    .logOut {
        p {
            padding: 10px;
        }
    }

    .menuItemEmail {
        border-top: 1px solid $gray;
        padding: 10px 20px 20px !important;
        margin-top: 10px !important;
        cursor: default;

        p {
            font-size: 12px;

            &:first-child {
                padding-top: 10px;
                padding-bottom: 5px;
            }

            &:last-child {
                color: $soft_blue !important;
            }
        }
    }

    .search {
        margin-top: 6px;
    }
}

.subNavChild {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    button {
        padding: 5px;
        margin-right: -10px;

        &:focus {
            outline: none;
        }
    }

    > div  {
        display: flex;
        align-items: center;

        img {
            margin-left: 14px;
        }
    }

    svg {
        color: $dark_red;
        width: 15px;
    }
}

.lineLoader {
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, $primary_blue, $primary_blue);
    background-color: $gray_white;
    margin: 0 0 -3px;
    position: relative;
    top: 8px;
    border-radius: 3px;
    background-size: 20%;
    background-repeat: repeat-y;
    background-position: -25% 0;
    opacity: 0;

    &.animate {
        opacity: 1;
        animation: scroll 1s ease-in-out infinite;
    }
}

@keyframes scroll {
    50% {
        background-size: 80%
    }
    100% {
        background-position: 125% 0;
    }
}
