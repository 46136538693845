@import '../../../styles/variables';

.menu {
    background: $darker_blue;

    :global {
        .ant-menu-submenu-open {
            //height: auto !important;
        }

        .ant-menu-sub {
            background-color: $dark_blue !important;

            li {
                padding-left: 38px !important;
            }
        }
        .ant-menu-item-selected {
            background: transparent !important;
        }

        .ant-menu-submenu-title {
            margin-inline: 0 !important;
        }

        .ant-menu-submenu-popup {
            //display: none !important;
        }

        .ant-menu-submenu-open .ant-menu-submenu-title,
        .ant-menu-submenu-active .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: $white;
            }
        }

        .sideNavIcon {
            svg {
                height: auto;
                width: 16px;
                transition: stroke 0.2s ease-in-out;
                fill: transparent;
                stroke: $manatee;
            }

            &.sideNavIconFill {
                svg {
                    fill: $manatee;
                    stroke: transparent;
                    transition: stroke 0.2s ease-in-out;
                }
            }
        }

        .ant-menu-submenu-open .ant-menu-submenu-title,
        .ant-menu-submenu-active .ant-menu-submenu-title,
        .ant-menu-item-selected,
        .ant-menu-item-active {
            .sideNavIcon {
                svg {
                    stroke: $white;
                }

                &.sideNavIconFill {
                    svg {
                        fill: $white;
                        stroke: transparent;
                    }
                }
            }
        }



    }

    li a {
        color: rgba(255, 255, 255, 0.65);

        &:hover {
            color: $white;
        }
    }
}

.popup {
    display: none !important;
}

.item {
    height: 35px !important;
    line-height: 35px !important;
    position: relative;
    margin-inline: 0 !important;
    margin-block: 0 !important;
    margin: 4px 0 8px 0 !important;
    width: 100% !important;
}

.subNav {
    li {
        display: flex;
        width: 100%;
        align-items: center;

        span:first-child {
            display: flex;
        }

        a {
            color: rgba(255, 255, 255, 0.65);

            &:hover {
                color: $white;
            }
        }
    }
}
