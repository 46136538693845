@import '../../../../../styles/variables';

.selectBox {
    width: 100%;

    :global {
        .ant-select-selector {
            background-color: transparent !important;
            border-color: $soft_light_gray !important;
            color: $white !important;
        }

        .ant-select-arrow {
            color: $white !important;
        }
    }
}
