@import "../../../styles/variables";

.subtitle {
    color: $soft_light_gray;
}

.linkBox {
    border: 1px solid $main_border_color;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(10, 3, 48, 0.1);
    border-radius: 4px;
    padding: 10px 12px;
    background-color: white;
    margin-right: 10px;

    span {
        color: $darker_blue;
    }
}

.linkContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.spinContainer {
    width: 100%;
}

.iconBtn {
    padding: 0 5px;
}

.copyIcon {
    font-size: 20px;
}

.externalIcon {
    width: 20px;
    height: 20px;
}

.buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;

    button {
        margin-right: 8px;
    }

    > span {
        margin-right: 2px;
        margin-left: 20px;
    }

    .socialBtn {
        padding: 5px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;

        svg {
            width: 30px;
            height: 30px;
        }
    }
}
