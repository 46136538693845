@import "../../styles/variables";

.loading {
    position: relative;
    overflow: hidden;
    height: 100vh;

    &:before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 998;
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.loader {
    position: absolute;
    display: flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.main {
    padding-bottom: 120px;
}

.mainLogo {
    background: white;
    padding: 16px;

    a {
        display: inline-flex;
        align-items: center;
    }
}

.rootRestricted {
    max-height: 100vh;
    overflow: hidden;
}

.restricted {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.50);
        backdrop-filter: blur(3px);
        z-index: 901;
    }

    .restrictedModal {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 902;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: rgba(234, 234, 246, 0.9);
        padding: 40px 0;

        h1 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 16px;
            margin-top: 0;
        }

        div {
            max-width: 680px;
            background-color: $white;
            padding: 20px 24px;
            border-radius: 4px;
            box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            font-size: 14px;
        }
    }
}

