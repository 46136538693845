@import '../../../../styles/variables';

.link {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 10px;

    > span {
        position: relative;
    }
}

.external {
    position: absolute;
    right: 14px;
    top: 1px;
    z-index: 10;

    svg {
        stroke: $manatee;
        width: 16px;
        height: auto;
    }
}

.activeItem {
    &.activeItem {
        &,
        i {
            color: $white !important;
        }

        svg {
            stroke: $white !important;
        }

        &.fillIcon {
            svg {
                fill: $white !important;
            }
        }
    }
}

.count {
    border: 1px solid $white;
    background-color: $orange;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    right: -18px;
    top: 3px;
    font-size: 9px;
    color: $white;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

