@import '../../styles/variables';

.sider {
    &,
    &.sider {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        height: 100vh;
        background-color: $darker_blue;

        @media #{$lg} {
            position: initial;
            height: auto;
        }
    }
}

// for large screens only keep the sidebar sticky to top on scroll
.siderContent {
    @media #{$lg} {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 99;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.head {
    padding: 10px 20px;
    border-bottom: 1px solid $softer_blue;
    margin-bottom: 20px;
}

.top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    a {
        display: block;
    }

    img {
        width: 30px;
        height: 30px;
        margin-top: 10px;
        display: block;
    }
}

.collapseBtn,
.unCollapseBtn {
    padding: 10px;
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;

    &:focus {
        outline: none;
    }

    svg {
        width: 15px;
        height: 15px;
        color: $manatee;
    }
}

.collapseBtn {
    overflow: hidden;
    margin-right: -13px;
    transition: all 0.2s;
}

.unCollapseBtn {
    display: none;
}

.settings {
    min-height: 35px;
    overflow: hidden;
    white-space: nowrap;
}

.collapsed {
    .collapseBtn,
    .settings {
        width: 0;
        height: 0;
        min-height: 0;
        padding: 0;
    }

    .unCollapseBtn {
        display: flex;
    }
}

.menu {
    max-height: calc(100vh - 160px);
}

.mobileHeader {
    background-color: $darker_blue;
    padding: 20px 0;

    .mobileWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        width: 30px;
        height: 30px;
        display: block;
    }

    svg {
        fill: $white;
        width: 24px;
        height: 24px;
    }

    button:focus {
        outline: none;
    }

    .rightSide {
        display: flex;
        align-items: center;

        button {
            margin-left: 4px;
        }
    }

    .helpBtn {
        color: $white;
        margin-right: 12px;
        svg {
            fill: $white;
            width: 20px;
            height: 20px;
        }
    }

    @media #{$lg} {
        display: none;
    }
}

.backToPrev {
    margin-top: 10px;
    padding-right: 20px;

    button {
        display: block;
        width: 100%;
    }
}

.referContainer {
    margin-top: 10px;
    border-top: 1px solid $softer_blue;
    padding: 16px;

    button {
        width: 100%;
        font-size: 13px;
    }
}

.giftContainer {
    padding-right: 6px;

    button {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 8px;
        height: 35px;
        font-size: 16px;

        svg {
            fill: $manatee;
            width: 16px;
            height: auto;
        }

        &:hover {
            svg {
                fill: $white;
            }
        }
    }
}
