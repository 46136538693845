@import "../variables";

#main {
    .ant-descriptions-viant-modal-maskew {
        .ant-descriptions-item-colon,
        .ant-descriptions-item-content {
            padding: 9px 16px;
            font-size: 13px;
            color: $black;
        }

        .ant-descriptions-item-colon {
            color: $manatee;
            background-color: transparent;
        }
    }

    .ant-descriptions-item-label {
        background: transparent;
        &.ant-descriptions-item-colon {
            color: $manatee;
        }
    }

    .ant-descriptions-item-content,
    .ant-descriptions-item-label {
        font-size: 13px;
        line-height: 1.2;
    }

    .ant-descriptions-small {
        td,
        th {
            padding: 9px 16px;
        }
    }
}
