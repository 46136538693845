@import "../../../styles/variables";

.container {
    padding: 0 20px;
    max-width: 1600px;

    &.fullWidth {
        max-width: 100%;
    }

    @media #{$md} {
        padding: 0 30px;
    }

    @media #{$xxl} {
        padding: 0 40px;
    }
}
