// Add the varaibles to this files , these varaibles can be shared accross other scss files
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

$white: #fff;
$black: #000;
$body_background_color: #fff;
$primary-btn-bg-color: #3853ff;
$primary-btn-color: #fff;
$font_family: "Inter", sans-serif;
$sidebar_nav_background: #061c3f;
$sidebar_nav_trigger_background: #fff;
$sidebar_navlink_hover_color: rgba(15, 47, 255, 0.13);
$sidebar_menuItem_color: rgba(255, 255, 255, 0.5);

$primary_blue: #3500ff;
$secondary_blue: #330df2;
$light_blue: #1890ff;
$dark_blue: #061c3f;
$soft_blue: #4f6990;
$darker_blue: #081C45;
$softer_blue: #355077;
$blue_bright: #373ac1;

$gray: #eaeef2;
$gray_light: #e8e4e4;
$gray_dark: #4f4f4f;
$soft_gray: #828d9f;
$soft_dark_gray: #747c88;
$ghost_white: #fbfbfd;
$gray_white: #eaeaf6;
$light_gray: #f7f9fb;
$gray_low: #f5f6f8;
$grayA: #aaa;
$gray6: #666;
$grayC: #ccc;
$grayE8: #e8e8e8;
$grayD1: #D1D1D1;
$grayD9: #d9d9d9;
$gray62: #626262;
$gray: #babacc;
$mischka: #D1D1E0;
$grayF0: #f0f0f0;
$grayf2fa: #F2F2FA;
$gray7: #777;

$yellow: #f3c319;
$soft_light_gray: #52527a;
$orange: #e28a00;

$manatee: #8585ad;
$purple: #AC86F9;
$green: #00CC99;
$darkGreen: #086549;
$red: #F20D59;
$pink: #f31968;
$dark_red: #800;

$main_border_color: $mischka;

$label-color: $soft_light_gray;
$label-size: 14px;
$label-weight: 500;

$input-color: $soft_light_gray;
$input-size: 12px;
$input-padding-top: 6px;
$input-padding-bottom: 5px;
$placeholder-color: $grayA;


$xs: 'only screen and (min-width: 480px)';
$sm: 'only screen and (min-width: 576px)';
$md: 'only screen and (min-width: 768px)';
$lg: 'only screen and (min-width: 992px)';
$xl: 'only screen and (min-width: 1200px)';
$xxl: 'only screen and (min-width: 1600px)';
$xxxl: 'only screen and (min-width: 1900px)';
$mobile: 'only screen and (max-width: 576px)';
