@import "../variables";

.ant-modal-root {
    .ant-modal-header {
        border-bottom: 0;
        padding-bottom: 10px;
    }

    .ant-modal-title {
        font-size: 18px;
        line-height: 1.3;
        color: #081c45;
        padding-right: 15px;

        @media #{$md} {
            font-size: 24px;
        }
    }

    .ant-modal-close {
        width: 38px;
        height: 38px;
        top: 10px;
        right: 10px;

        &:hover {
            background-color: transparent;
        }

        &,
        &:focus,
        span:focus {
            outline: none;
        }

        span {
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            outline: none;
        }
    }

    .ant-modal-mask {
        background: rgba(234, 234, 246, 0.9);
    }
}
