@import "../variables";

#main {
    .ant-form-explain {
        padding: 4px 5px 0 5px;
    }

    .ant-form-item-explain {
        margin-top: 6px;
        font-size: 13px;
        line-height: 1.2;
        color: $softer_blue;
        font-weight: normal;
    }

    // set fort item labels styles based on new design
    .ant-form-item-label {
        padding-bottom: 4px !important;

        label {
            font-size: $label-size;
            color: $label-color;
            font-weight: $label-weight;

            svg {
                color: $label-color;
            }
        }
    }
}
