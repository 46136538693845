@import "variables";

/* Primary Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: $font_family;
}

html {
    height: 100%;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;

    div {
        font-weight: normal;
    }
}

h1 {
    font-size: 1.4em;
}

em {
    font-style: normal;
}

p {
    font-weight: normal;
    margin-top: 0;
}

a {
    text-decoration: none;
}

.geosuggest__suggests-wrapper ul {
    border: none;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    z-index: 1050;
    box-sizing: border-box;
    font-size: 14px;
    font-variant: normal;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    li {
        padding: 4px 15px;
        margin: 5px 0;
        font-size: 0.8em;
        line-height: 1.1em;
    }
}

// Custom scroll scroller - react-custom-scroll
.rcs-inner-handle {
    background-color: $soft_blue !important;
}

// help button
iframe#launcher {
    bottom: 35px !important;
    z-index: 902!important;
}

button {
    background-color: transparent;
    background-image: none;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.Toastify__close-button--light {
    color: $black;

    svg {
        width: 18px;
        height: 18px;
    }
}

body {
    .react-tel-input {
        .form-control {
            width: 100%;
            height: 32px;
            border-color: $mischka;
            color: $soft_light_gray;
            font-size: 12px;

            &::placeholder {
                color: $mischka;
            }
        }
    }
}

// hide zendesck help button on rentGPTSettings page
.rentGPTSettings {
    #launcher {
        display: none;
    }
}

.show-more-less-clickable {
    color: $secondary_blue;
    cursor: pointer;
    font-size: 13px;
}

