.push {
    position: fixed;
    right: -580px;
    top: 20px;
    transition: all 0.4s;
    z-index: 1010;

    &.visible {
        right: 20px;
    }

    &.center {
        right: 50%;
        transform: translate(50%, 0);
    }
}

.room {
    margin-bottom: 20px;
}
