@import "./variables";

.dropdownAsBtn {
    display: flex;
    align-items: center;
    cursor: pointer;

    border: 1px solid $main_border_color;
    padding: 7px;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    line-height: 1.2;
    color: $darker_blue;
    box-shadow: 0 0 2px rgba(10, 3, 48, 0.1);

    svg {
        margin-top: 2px;
        margin-left: 4px;
    }
}

.mb0 {
    margin-bottom: 0;
}

.mt0 {
    margin-top: 0;
}

.mb10 {
    margin-bottom: 10px;
}

// Form Item margin bottom value
.mb24 {
    margin-bottom: 24px;
}

.ml8 {
    margin-left: 8px;
}

.radioLabel {
    font-size: 13px;
    font-weight: 500;
    color: $dark_blue;
    margin-left: 10px;

    svg {
        width: 14px;
        height: auto;
        margin-top: -3px;
    }
}

.radioBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    svg {
        margin-left: 4px;
    }
}

.formLabel {
    padding-bottom: 4px;
    display: inline-flex;
    font-size: $label-size;
    color: $label-color;
    font-weight: $label-weight;

    > span {
        color: $primary_blue;
    }
}

.labelWithHelpIcon {
    display: inline-flex;
    align-items: center;
    padding-bottom: 8px;

    label {
        margin-bottom: 0;
        padding-bottom: 0;
        margin-right: 4px;
    }
}

.fullWidth {
    width: 100%;
}

.formSectionHelpText {
    font-size: 11px;
    color: $soft_gray;
    font-weight: 400;
    margin-bottom: 0;

    span {
        color: #f5222d;
        margin-right: 4px;
        font-family: SimSun, sans-serif;
        font-size: 13px;
    }
}

.errorMsg {
    font-size: 12px;
    color: $red;

    &.large {
        font-size: 14px;
    }
}

.warnMsg {
    font-size: 12px;
    color: $orange;

    &.large {
        font-size: 14px;
    }
}


.capitalize {
    text-transform: capitalize;
}

.noWordBreak {
    white-space: nowrap;
}

.selectDropDown {
    width: auto !important;
    min-width: 240px !important;
    max-width: 450px !important;
}


.popConfirm {
    max-width: 280px;
    display: inline-block;
}

.breakWord {
    word-break: break-word;
}

.moreLessText {
    span {
        width: auto !important;
        max-width: initial !important;
    }
}
