@import "../variables";

#main {
    .ant-input {

        &::placeholder {
            color: $placeholder-color;
        }

        &,
        &:focus {
            box-shadow: none;
        }

        &.ant-input-sm {
            font-size: 12px;
            height: 24px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-input-number-affix-wrapper {
        width: 100%;

        .ant-input-number-prefix {
            margin-left: -4px;
        }

        .ant-input-number-disabled {
            background: transparent;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-form-item-extra {
        margin-top: 4px;
        font-size: 12px;
        color: $black;
    }
}
