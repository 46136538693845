@import "../../styles/variables";

.alert {
    margin-top: 16px;
}

.buttons {
    display: inline-flex;

    button {
        margin-right: 6px;
    }
}

.message {
    @media #{$lg} {
        display: flex;
    }
}

.pushRight {
    @media #{$xl} {
        margin-right: 290px;

    }
}


.activateModal {
    max-width: 380px !important;
    min-width: 250px !important;
}
