@import '../../../../styles/variables';

.box {
    display: flex;
    align-items: center;
}

.helpBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    text-transform: uppercase;
    border: 1px solid $main_border_color;

    svg {
        fill: $darker_blue;
        margin-right: 4px;
        width: 18px;
        height: auto;
    }

    &.onLargeOnly {
        display: none;

        @media #{$lg} {
            display: flex;
        }
    }
}

