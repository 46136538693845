@import '../../../styles/variables';

:global {
    .ant-btn.ant-tooltip-open {
        font-size: 12px;
    }

    .ant-btn-lg.ant-tooltip-open {
        font-size: 16px;
    }
}

.btn {
    font-weight: 500;
    text-transform: none;
}

.green {
    &,
    &.green,
    &:hover {
        background-color: $green;
    }

    &.green,
    & {
        &:hover {
            background-color: $green;
        }
    }

    &.ghost {
        color: $green;
        border-color: $green;
        background-color: transparent;

        &:hover, &:focus, &:active {
            color: $green !important;
            border-color: $green !important;
        }
    }

    &:disabled:hover {
        background-color: transparent;
        border-color: $mischka !important;

        svg {
            fill: $mischka;
        }
    }
}

.gray {
    &, &.gray, &:hover {
        color: $manatee !important;
        background-color: $gray_white !important;
        border: none;
    }
}
