@import "../variables";

#main {
    .ant-pagination {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        float: none !important;
        box-sizing: border-box;

        &.ant-table-pagination {
            margin: 0;
            padding: 20px 12px;
        }

        .ant-pagination-options {
            display: none;
        }

        li {
            min-width: 26px;
            width: 26px;
            height: 26px;

            background-color: transparent;

            &,
            &.ant-pagination-prev span,
            &.ant-pagination-next span {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .ant-pagination-item-link {
            background-color: transparent;
        }

        .ant-pagination-item-link {
            border: 0;
        }

        .ant-pagination-item {
            border: 0;
            border-radius: 50%;
            font-size: 14px;
            line-height: 1;

            &,
            &:hover {
                color: $darker_blue;
            }
        }

        .ant-pagination-item-active {
            background-color: $grayD1;

            a {
                color: $darker_blue;
            }
        }

        &.mini {
            li {
                width: auto;
                height: auto;
            }
        }

        .ant-pagination-jump-next,
        .ant-pagination-jump-prev {
            span {
                font-size: 10px;
                line-height: 34px;
            }
        }

        @media #{$xs} {
            li {
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ant-pagination-item {
                font-size: 16px;
            }
        }
    }
}
