@import "../../../styles/variables";

div.ant-modal {
    min-width: 42.5rem;

    @media (max-width: 41.999rem) {
        min-width: 100%;
    }
}

/* end of color picker */

div .ant-popover {
  z-index: 10000000000000;
}

/* end of recent activity */

.customOptions {
    margin-bottom: 12px;
    @media (max-width: 767px) {
        width: 100%;
    }

    .addContent {
        background: $white;
        margin-right: 6px;
        border: 1px solid $gray;
        box-sizing: border-box;
        border-radius: 4px;
        display: inline-block;
        cursor: pointer;
        transition: all 0.4s;
        @media (max-width: 450px) {
            width: 100%;
            text-align: left;
            margin: 0 0 10px;
        }

        a,
        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: $dark_blue;
            padding: 9px 12px;
            display: block;
        }

        img {
            margin-right: 11px;
        }

        &:hover {
            a,
            span {
                color: $soft_dark_gray;
            }
        }
    }
}

//.ant-message {
//    z-index: 1000000000000000 !important;
//}

div#local-media-div video {
    display: none;

    &:nth-of-type(1) {
        display: block;
    }
}

span.mobile-table {
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}

.ant-form-item-children {
    display: block;
}


