@import '../../../../styles/variables';

.btnsRow {
    button {
        width: 100%;
        margin-top: 12px;
    }

    @media #{$md} {
        display: flex;
        align-items: center;
        margin-bottom: -12px;

        button {
            width: auto;
            margin-top: 0;
        }
    }
}

.main, .extra {
    > div {
        margin-bottom: 0;
    }

    @media #{$md} {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > div {
            margin-bottom: 0;
        }

        a, button, > div {
            margin-right: 10px;
            margin-bottom: 12px;

            button:first-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}

.separator {
    display: none;

    @media #{$md} {
        display: block;
        margin-right: 10px;
        margin-bottom: 12px;
        width: 1px;
        height: 20px;
        background-color: $grayC;
    }
}
