@import '../../../../styles/variables';

$width: 290px;
$xlWidth: 380px;

.helpSider {
    width: 0;
    background-color: $white;
    position: fixed;
    right: 0;
    top: 0;
    overflow: hidden;
    min-height: 100vh;
    z-index: 901;
    transition: all 0.3s ease-in-out;
    border-left: 1px solid $main_border_color;

    @media #{$xxl} {
        position: relative;
        right: auto;
        top: auto;
    }

    &.open {
        width: $width;

        @media #{$xxxl} {
            width: $xlWidth;
        }
    }

    &.onlyFixed {
        @media #{$xxl} {
            position: fixed;
            right: 0;
            top: 0;

            .wrap {
                position: initial;
            }
        }
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    width: $width;
    min-width: $width;
    padding-bottom: 40px;

    @media #{$xxl} {
        position: fixed;
        //right: 0;
        //top: 0;
        height: calc(100vh - 105px);
        overflow-y: auto;
    }

    @media #{$xxxl} {
        width: $xlWidth;
        min-width: $xlWidth;
    }
}

.box {
    display: flex;
    align-items: center;
}

.txt {
    display: none;

    @media #{$lg} {
        margin-left: 4px;
        display: block;
    }
}

.videoIframe {
    width: 100%;
    aspect-ratio: 16/9;
}

.closeBtn {
    color: $dark_blue;
}

.titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 16px 0 0 16px;

    > div {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
        align-items: center;

        svg {
            width: 28px;
            height: 28px;
        }
    }

    .title {
        margin-left: 4px;
        color: #8E8E8E;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;

        p {
            color: #081C45;
            font-size: 16px;
            font-weight: 500;
            margin: 0;
        }
    }
}

.header {
    > div {
        flex-direction: row-reverse;

        button {
            margin-inline-end: -8px !important;
        }
    }
}

.titleLine {
    background: $darker_blue;
    color: $white;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 16px;
    //margin: 0 -24px 16px;
}

.list {
    list-style: none;
    //margin: 0 -24px;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 0.5px solid #D4E2FF;
        padding: 8px 16px;
        font-size: 14px;
    }
}

.mb16 {
    margin-bottom: 16px;
}
