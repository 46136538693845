@import "../variables";

#main {
    .ant-select:not(.ant-color-picker-format-select) {
        width: 100%;

        .ant-select-selection-placeholder {
            color: $placeholder-color;
        }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
    }

    .ant-select-selection-item {
        .subLabel, a, em {
            display: none;
        }
    }

    .ant-select-multiple {
        .ant-select-selection-item {
            background-color: $primary_blue;
            color: $white;
            font-size: 13px;
            height: 22px;
            line-height: 20px;

            .extraLabel {
                a {
                    display: inline-flex;

                    svg {
                        width: 14px;
                        height: 14px;
                        stroke: $white;
                    }
                }
            }
        }

        .ant-select-selection-item-remove {
            display: flex;
            align-items: center;
            padding: 0 4px;
            margin-right: -4px;
            margin-left: -3px;

            svg {
                fill: $white;
                font-size: 11px;
            }
        }

        .ant-select-selection-search {
            min-width: 100px;
        }
    }

}
